import React, { Component } from "react";
import { useRouteMatch } from "react-router-dom";
import Header from "../components/Header/header";
import Hero from "../components/HeroSection/HeroSectionInnerPage";
import Breadcrumb from "../components/Breadcrumb";
import Blog from "../components/Blog/blogSingleLeftSidebar";
import Footer from "../components/Footer";

const Theme = () => {
  const [blog, setBlog] = React.useState(undefined);
  const route = useRouteMatch();
  React.useEffect(() => {
    fetch(
      `https://api.wcanperf.com/blog/${route.params.id}/get-blog`
    )
      .then((response) => response.json())
      .then((resBlog) => {
        setBlog(resBlog);
      });
  }, []);
  return (
    blog != undefined && (
      <React.Fragment>
        <Header />
        <div className="main">
          <Hero pageTitle={blog[0].title} />
          <Breadcrumb name={blog[0].title} />
          <Blog blog={blog[0]}/>
        </div>
        <Footer withoutNewsletter={true} />
      </React.Fragment>
    )
  );
};

export default Theme;
