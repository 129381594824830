import React from "react";
import { connect } from "react-redux";

class Header extends React.Component {
    render() {
        return (
            <React.Fragment>
                <header className="header">
                    <nav className="navbar navbar-expand-lg fixed-top">
                        <div className="container">
                            <a className="navbar-brand" href="/">
                                <img src={this.props.isColorLogo && this.props.isColorLogo === true ? "assets/img/logo-color.png" : "assets/img/logo.webp"}
                                    alt="logo" className="img-fluid" style={{
                                        width: "100px",
                                        height: "100px"
                                    }} />
                            </a>
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="ti-menu"></span>
                            </button>

                            <div className="collapse navbar-collapse h-auto" id="navbarSupportedContent">
                                <ul className="navbar-nav ml-auto menu">
                                    <li><a href="/#" className="page-scroll"> Ana Sayfa</a>
                                    </li>
                                    <li><a href="/#features" className="page-scroll">Avantajlar</a></li>
                                    <li><a href="/#process" className="page-scroll">Nasıl Çalışır ?</a></li>
                                    <li><a href="/#contact" className="page-scroll">İletişim</a></li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </header>
            </React.Fragment>
        );
    }
}

export default connect(state => ({}))(Header);
