import React, { Component } from "react";
class Blog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blogs: [],
      totalPage: [],
      page: 0,
      url: "Ahmet Öldüğ",
    };
  }

  getBlogs = (page) => {
    this.setState({ page: page });
    fetch(
      `https://api.wcanperf.com/blog/get-all-blogs?page=${page}`
    )
      .then((response) => response.json())
      .then((resBlogs) => {
        this.setState({ blogs: resBlogs.blogs });
        this.setState({
          totalPage: Array(Math.ceil(Number(resBlogs.totalBlogLength / 9)))
            .fill()
            .map((_, index) => index + 1),
        });
      });
  };
  componentDidMount() {
    this.getBlogs(0);
  }

  render() {
    return (
      <React.Fragment>
        <section className="our-blog-section ptb-100">
          <div className="container">
            <div className="row">
              {this.state.blogs.length != 0 ?
                this.state.blogs.map((blog) => {
                  var date = new Date(blog.createdAt);
                  const options = { month: "long" };
                  return (
                    <div className="col-md-6 col-lg-4" key={blog._id}>
                      <div className="single-blog-card card gray-light-bg border-0 shadow-sm my-3">
                        <div className="blog-img position-relative">
                          <img
                            src={blog.images[0]}
                            className="card-img-top"
                            alt="blog"
                          />
                          <div className="meta-date">
                            <strong>{date.getDate()}</strong>
                            <small>
                              {new Intl.DateTimeFormat("tr-TR", options).format(
                                date
                              )}
                            </small>
                          </div>
                        </div>
                        <div className="card-body">
                          <h3 className="h5 mb-2 card-title">
                            <a href={`/blog/${blog.title.replace(/ /g, "-")}`}>
                              {blog.title}
                            </a>
                          </h3>
                          <p className="card-text">{blog.subtitle}</p>
                          <a
                            href={`/blog/${blog.title.replace(/ /g, "-")}`}
                            className="detail-link"
                          >
                            Daha Fazla <span className="ti-arrow-right"></span>
                          </a>
                        </div>
                      </div>
                    </div>
                  );
                }):<p>Henüz Oluşturulmuş bir blog bulunamadı.</p>
        }
            </div>

            <div className="row">
              <div className="col-md-12">
                { this.state.totalPage.length != 0 && (
                  <nav className="custom-pagination-nav mt-4">
                    <ul className="pagination justify-content-center">
                      <li className="page-item">
                        <a
                          className="page-link"
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            this.state.page - 1 != -1 &&
                              this.getBlogs(this.state.page - 1);
                          }}
                        >
                          <span className="ti-angle-left"></span>
                        </a>
                      </li>
                      {this.state.totalPage.map((pg) => (
                        <li
                          className={
                            this.state.page === pg - 1
                              ? "page-item active"
                              : "page-item"
                          }
                          key={pg}
                        >
                          <a
                            className="page-link"
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              this.getBlogs(Number(e.target.textContent) - 1);
                            }}
                          >
                            {pg}
                          </a>
                        </li>
                      ))}
                      <li className="page-item">
                        <a
                          className="page-link"
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            this.state.page + 1 !=
                              this.state.totalPage[
                                this.state.totalPage.length - 1
                              ] && this.getBlogs(this.state.page + 1);
                          }}
                        >
                          <span className="ti-angle-right"></span>
                        </a>
                      </li>
                    </ul>
                  </nav>
                )}
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Blog;
