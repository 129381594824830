module.exports = {
  contact: {
    title: "Çalışanlarınızın Verimliliğini Arttırmanın En İyi Yolu",
    description:
      "Bize ulaşın. Firmanızın verimliliğini siz ve ekibiniz ile beraber en üst seviyeye çıkaralım.",

    addressTitle: "Adres",
    addressIcon: "fas fa-location-arrow",
    address: "Fulya Mahallesi Yeşilçimen Sokak Polat Tower Bağımsız Bölüm 430, 34394 Şişli/İstanbul",
    emailTitle: "Mail",
    emailIcon: "fas fa-envelope",
    email: "info@wcanperf.com"
  }
};
