import React from "react";
import { connect } from "react-redux";

const Blog = ({ blog }) => {
  return (
    <React.Fragment>
      <div className="module ptb-100">
        <div className="container pl-5 pr-5 pl-sm-0 pr-sm-0">
          <div className="row">
            <article className="post w-100">
              <div className="post-preview d-flex justify-content-center ">
                <img
                  src={blog.images[0]}
                  alt="article"
                  className="img-fluid"
                  style={{
                    width:'100%',
                    textAlign: "center",
                    objectFit: "contain",
                  }}
                />
              </div>
              <div className="post-wrapper">
                <div className="post-header">
                  <h1 className="post-title">{blog.title}</h1>
                  <ul className="post-meta">
                    <li>
                      {
                        new Date(blog.createdAt)
                          .toLocaleDateString("en-GB")
                          .split(",")[0]
                      }
                    </li>
                  </ul>
                </div>
                <div className="post-content" dangerouslySetInnerHTML={{ __html: blog.text }}></div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default connect((state) => ({
  state,
}))(Blog);
