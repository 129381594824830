import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// importing all the themes
import Theme7 from "../themes/theme7";
import Login from "../themes/login";
import SignUp from "../themes/signup";
import ResetPassword from "../themes/resetPassword";
import Faq from "../themes/faq";
import NotFound from "../themes/404";
import ComingSoon from "../themes/comingSoon";
import Team from "../themes/team";
import SingleTeam from "../themes/singleTeam";
import BlogDefault from "../themes/blogDefault";
import BlogNoSidebar from "../themes/blogNoSidebar";
import BlogLeftSidebar from "../themes/blogLeftSidebar";
import BlogSingleLeftSidebar from "../themes/blogSingleLeftSidebar";
import BlogSingleRightSidebar from "../themes/blogSingleRightSidebar";
import AboutUs from "../themes/aboutUs";
import ContactUs from "../themes/contactUs";
import WorkerRequest from "../themes/workerRequest"

export default class Routes extends React.PureComponent {
  render() {
    return (
      <React.Fragment>
        <Router>
          <Switch>
            <Route exact path="/" component={Theme7} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/signup" component={SignUp} />
            <Route exact path="/resetPassword" component={ResetPassword} />
            <Route exact path="/faq" component={Faq} />
            <Route exact path="/404" component={NotFound} />
            <Route exact path="/comingSoon" component={ComingSoon} />
            <Route exact path="/team" component={Team} />
            <Route exact path="/singleTeam" component={SingleTeam} />
            <Route exact path="/blog" component={BlogDefault} />
            <Route exact path="/blogNoSidebar" component={BlogNoSidebar} />
            <Route exact path="/blog/:id" component={BlogSingleLeftSidebar}   />
            <Route exact path="/:companyid" component={WorkerRequest}   />
            <Route exact path="/blogSingleRightSidebar" component={BlogSingleRightSidebar} />
            <Route exact path="/aboutUs" component={AboutUs} />
            <Route exact path="/contactUs" component={ContactUs} />
            {/* <Route exact path="/blog" component={BlogLeftSidebar} /> */}
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}
