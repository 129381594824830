import React, { useState } from "react";
import { useParams } from "react-router-dom";

const verifyCompany = async (data) => {
  return await fetch(`https://api.wcanperf.com/company/companyVerify`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    redirect: "follow",
  });
};

const sendRequest = async (data) => {
  return await fetch(`https://api.wcanperf.com/worker/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },

    body: JSON.stringify(data),
    redirect: "follow",
  });
};

const Survey = ({
  question,
  answer,
  handleAnswer,
  handleNext,
  companyName,
  validError,
  unexpectedError,
}) => {
  return (
    <div
      className="card p-2 m-2 p-md-5 m-md-5"
      style={{
        zIndex: 5,
        minWidth: "22rem",
        maxWidth: "35rem",
        minHeight: "42rem",
        width: "35rem",
        borderRadius: ".75rem",
        zIndex: 5,
      }}
    >
      <h2 className="text-center">{question}</h2>
      <h4 className="text-center ">{companyName}</h4>
      {validError && <ValidErrorMessage message={"Bu alan boş bırakılamaz."} />}
      {unexpectedError && (
        <ValidErrorMessage message={"Beklenmedik bir hata oluştu."} />
      )}

      <form>
        <div className="form-group">
          <label htmlFor="message" className="mb-2 d-block ">
            Cevabınız:
          </label>
          <textarea
            id="asnwer"
            placeholder="Cevabınız..."
            className="w-100 rounded-md form-control"
            rows={12}
            required
            value={answer}
            onChange={handleAnswer}
          />
        </div>
        <button className="submit-button" onClick={handleNext}>
          Devam Et
        </button>
      </form>
    </div>
  );
};

const AddRequest = () => {
  const { companyid } = useParams();

  const [validError, setValidError] = useState(false);
  const [unexpectedError, setUnexpectedError] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [token, setToken] = useState("");
  const [index, setIndex] = useState(0);
  const [results, setResults] = useState([]);
  const [answer, setAnswer] = useState("");

  // Survey Next Button Click
  const handleNext = async (e) => {
    e.preventDefault();
    if (questions.length === index + 1) {
      answer === ""
        ? setValidError(true)
        : handleSubmit([...results, { question: questions[index], answer }]);
    } else {
      answer === "" ? setValidError(true) : setIndex(index + 1);

      setResults((prev) => [
        ...prev,
        {
          question: questions[index],
          answer,
        },
      ]);
    }
    answer != "" && setValidError(false);
    setAnswer("");
  };

  // Survey Answer Text Change
  const handleAnswer = (e) => {
    setAnswer(e.target.value);
  };

  // Company Id Verify Button Click
  const onClickVerifyCompany = async (e) => {
    e.preventDefault();
    const response = await verifyCompany({
      fullName: companyid,
      key: token,
    });
    if (response.status === 201) {
      response.json().then((data) => setQuestions(data));
      setValidError(false);
    } else {
      setValidError(true);
    }
  };

  // Survey Submit
  const handleSubmit = async (data) => {
    const response = await sendRequest({
      companyName: companyid,
      companyKey: token,
      answer: data,
    });
    if (response.status === 201) {
      setIndex(index + 1);
    } else {
      setUnexpectedError(true);
    }
  };

  return (
    <div
      className="d-grid justify-content-center align-items-center w-100 position-absolute "
      style={{
        minHeight: "100vh",
        background: "linear-gradient(75deg, #4f1bc5, #7b17c5)",
      }}
    >
      {index < questions.length ? (
        <Survey
          question={questions[index]}
          answer={answer}
          handleAnswer={handleAnswer}
          handleNext={handleNext}
          companyName={companyid.toUpperCase()}
          validError={validError}
          unexpectedError={unexpectedError}
        />
      ) : questions.length === 0 ? (
        <div
          className="card p-2 m-2 p-md-5 m-md-5"
          style={{
            zIndex: 5,
            minWidth: "22rem",
            maxWidth: "35rem",
            minHeight: "42rem",
            borderRadius: ".75rem",
            zIndex: 5,
          }}
        >
          <h2 className="text-center">Şirket ID'inizi Giriniz. </h2>
          <h4 className="text-center ">{companyid.toUpperCase()}</h4>
          {validError && (
            <ValidErrorMessage
              message={
                "Şirket isminiz veya şirket ID’niz yanlış. Lütfen kontrol edin."
              }
            />
          )}

          <form>
            <div className="form-group">
              <label htmlFor="message" className="mb-2 d-block ">
                ID:
              </label>
              <input
                id="message"
                placeholder="ID..."
                className="w-100 rounded-md form-control"
                rows={12}
                required
                value={token}
                onChange={(e) => setToken(e.target.value)}
              />
            </div>
            <button className="submit-button" onClick={onClickVerifyCompany}>
              Devam Et
            </button>
          </form>
        </div>
      ) : (
        <div
          className="card p-2 m-2 p-md-5 m-md-5"
          style={{
            zIndex: 5,
            minWidth: "22rem",
            minHeight: "10rem",
            borderRadius: ".75rem",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src="assets/img/success-send.jpg"
            alt="test"
            width={400}
            height={300}
          />

          <h2 className="text-center">Mesajınız alındı teşekkür ederiz. </h2>
          <a
            href="/"
            className="btn "
            style={{ backgroundColor: "#6019c5", color: "#fff" }}
          >
            Ana sayfaya geri dön
          </a>
        </div>
      )}
    </div>
  );
};

const ValidErrorMessage = ({ message }) => (
  <div className="alert alert-danger mt-3">{message}</div>
);

export default AddRequest;
