import React from "react";

class Feature extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        /**
         * Your ajax will goes here to get data then call setState
         */
    }

    render() {
        return (
            <React.Fragment>
                <div id="features" className={"feature-section ptb-100 " + (this.props.isGray && this.props.isGray === true ? 'gray-light-bg' : '')}>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-9 col-lg-9">
                                <div className="section-heading text-center mb-5">
                                    <h2>WPERF avantajları</h2>

                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center justify-content-md-center">
                            <div className="col-lg-4 col-md-12">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="d-flex align-items-start mb-sm-0 mb-md-3 mb-lg-3">
                                            <span className="ti-face-smile icon-size-md color-secondary mr-4"></span>
                                            <div className="icon-text">
                                                <h5 className="mb-2"></h5>
                                                <p>Çalışanlarınıza verilen eğitimler ile kendilerinin ve fikirlerinin ne kadar değerli olduğu hissettirilir.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="d-flex align-items-start mb-sm-0 mb-md-3 mb-lg-3">
                                            <span className="ti-vector icon-size-md color-secondary mr-4"></span>
                                            <div className="icon-text">
                                                <h5 className="mb-2"></h5>
                                                <p>Çalışanlar yaptıkları tüm tavsiye, şikayet ve fikirlerin anonim olarak gideceğini bildiği için samimi bilgiler elde edilir.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="d-flex align-items-start mb-sm-0 mb-md-3 mb-lg-3">
                                            <span className="ti-headphone-alt icon-size-md color-secondary mr-4"></span>
                                            <div className="icon-text">
                                                <h5 className="mb-2"></h5>
                                                <p>Çalışanlar sıkıcı anketler yerine tek soruluk Perf ile değinmek istedikleri tüm konularda bildirim yaparlar. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-5 d-none d-sm-none d-md-block d-lg-block">
                                <div className="position-relative pb-md-5 py-lg-0">
                                    <img alt="placeholder" src="assets/img/calisan_verimliligi.png" className="img-center img-fluid" />
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="d-flex align-items-start mb-sm-0 mb-md-3 mb-lg-3">
                                            <span className="ti-layout-media-right icon-size-md color-secondary mr-4"></span>
                                            <div className="icon-text">
                                                <h5 className="mb-2"></h5>
                                                <p>Çalışanları doğrudan çalıştıkları firmaya değil aracı kuruluşa anonim olarak yazdıkları için daha samimi olurlar.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="d-flex align-items-start mb-sm-0 mb-md-3 mb-lg-3">
                                            <span className="ti-layout-cta-right icon-size-md color-secondary mr-4"></span>
                                            <div className="icon-text">
                                                <h5 className="mb-2"></h5>
                                                <p>Firmalar gelen geri bildirimleri yapay zeka sayesinde işlevsel veri olarak alır.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="d-flex align-items-start mb-sm-0 mb-md-3 mb-lg-3">
                                            <span className="ti-palette icon-size-md color-secondary mr-4"></span>
                                            <div className="icon-text">
                                                <h5 className="mb-2"></h5>
                                                <p>Firmaların eksikleri istenilirse uzman ekibimiz tarafından geliştirilen sistemlerle giderilir.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Feature;
